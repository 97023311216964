export class ScopeSeverity {
    public static data = [
        {
            "code": "A",
            "scope": "1",
            "level": "1"
        },
        {
            "code": "B",
            "scope": "2",
            "level": "1"
        },
        {
            "code": "C",
            "scope": "3",
            "level": "1"
        },
        {
            "code": "D",
            "scope": "1",
            "level": "2"
        },
        {
            "code": "E",
            "scope": "2",
            "level": "2"
        },
        {
            "code": "F",
            "scope": "3",
            "level": "2"
        },
        {
            "code": "G",
            "scope": "1",
            "level": "3"
        },
        {
            "code": "H",
            "scope": "2",
            "level": "3"
        },
        {
            "code": "I",
            "scope": "3",
            "level": "3"
        },
        {
            "code": "J",
            "scope": "1",
            "level": "4"
        },
        {
            "code": "K",
            "scope": "2",
            "level": "4"
        },
        {
            "code": "L",
            "scope": "3",
            "level": "4"
        }
    ]
}