<template>
  <div class="home">
    <h2>Project Exiting Information</h2>

    <v-snackbar
        top
        multi-line
        :color="snackbar.color"
        v-model="snackbar.visible"
    >
      {{this.snackbar.text}}
    </v-snackbar>

    <v-row class="mt-3" dense>
      <v-col cols="12" lg="5">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Facility Name"
            type="input"
            v-model="evaluation.providerName"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="3">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="County ID"
            type="input"
            v-model="evaluation.providerCountyId"
        />
      </v-col>
      <v-col cols="12" lg="5">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="County Name"
            type="input"
            v-model="evaluation.providerCounty"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="3">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Project Number"
            type="input"
            v-model="evaluation.evaluationNumber"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Provider Type"
            type="input"
            v-model="evaluation.providerType"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="3">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Survey Type ID"
            type="input"
            v-model="evaluation.surveyType"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Survey Type"
            type="input"
            v-model="evaluation.surveyTypeName"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="3">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Federal ID"
            type="input"
            v-model="evaluation.fedId"
        />
      </v-col>
      <v-col cols="12" lg="5">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="Email Address"
            type="input"
            v-model="evaluation.emailAddr"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="3">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="License Number"
            type="input"
            v-model="evaluation.licNbr"
        />
      </v-col>
      <v-col cols="12" lg="5">
        <v-text-field
            rounded
            readonly
            class="text-black"
            label="License Name"
            type="input"
            v-model="evaluation.licName"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="2">
        <v-text-field
            :rounded="isReadOnly()"
            :readonly="isReadOnly()"
            label="Fiscal Year"
            type="input"
            v-model="evaluation.fiscalYear"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field
            :rounded="isReadOnly()"
            :readonly="isReadOnly()"
            label="Type of Evaluation"
            type="input"
            v-model="evaluation.evaluationType"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
            :rounded="isReadOnly()"
            :readonly="isReadOnly()"
            label="Date Entered"
            type="date"
            v-model="evaluation.dueDate"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
            :rounded="isReadOnly()"
            :readonly="isReadOnly()"
            label="Date Exited"
            type="date"
            v-model="evaluation.endDate"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="2">
        <v-checkbox
            label="Enforcement?"
            :disabled="isReadOnly()"
            v-model="exitInfo.enforcement"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-checkbox
            label="Licensing Action?"
            :disabled="isReadOnly()"
            v-model="exitInfo.licensingAction"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-checkbox
            label="Maltreatment?"
            :disabled="isReadOnly()"
            v-model="exitInfo.maltreatment"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-checkbox
            label="Aborted?"
            :disabled="isReadOnly()"
            v-model="exitInfo.aborted"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="3">
        <v-radio-group
            row
            label="Initial: "
            :disabled="isReadOnly()"
            v-model="evaluation.initialIndicator"
        >
          <v-radio label="Yes" :value="'I'"/>
          <v-radio label="No" :value="null"/>
        </v-radio-group>
      </v-col>
      <v-col cols="12" lg="3">
        <v-radio-group
            row
            label="CHOW: "
            :disabled="isReadOnly()"
            v-model="evaluation.chowIndicator"
        >
          <v-radio label="Yes" :value="'Y'"></v-radio>
          <v-radio label="No" :value="null"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" lg="2"/>
    </v-row>

    <v-row dense>
      <v-col cols="12" lg="2">
        <v-text-field
            :readonly="isReadOnly()"
            label="Correction Orders"
            type="number"
            v-model="exitInfo.correctionOrders"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-autocomplete
            :readonly="isReadOnly()"
            label="Scope and Level Code"
            v-model="exitInfo.scopeLevelCode"
            @input="scopeLevelChange()"
            :items="scopeCodes"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
            :rounded="!isReadOnly()"
            readonly
            label="Highest Scope"
            type="text"
            v-model="exitInfo.scope"
        />
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
            :rounded="!isReadOnly()"
            readonly
            label="Highest Level"
            type="text"
            v-model="exitInfo.level"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-radio-group
            row
            style="font-size: 20px"
            label="Schedule a Follow-up?: "
            :readonly="isReadOnly()"
            v-model="followUp">
          <v-radio label="Yes" value="y"/>
          <v-radio label="No" value="n"/>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row dense class="mt-5">
      <v-col cols="12" lg="3">
        <v-checkbox
            label="Request Informal Conference"
            :disabled="isReadOnly()"
            v-model="exitInfo.conferenceRequest"
        />
        <v-checkbox
            label="Immediate Fines"
            :disabled="isReadOnly()"
            v-model="exitInfo.immediateFines"
        />
        <v-checkbox
            label="Immediate Correction Orders"
            :disabled="isReadOnly()"
            v-model="exitInfo.immediateOrders"
        />
        <v-checkbox
            label="License Revocation"
            :disabled="isReadOnly()"
            v-model="exitInfo.licenseRevocation"
        />
      </v-col>
      <v-col cols="12" lg="3">
        <v-checkbox
            label="Request Plan of Corrections"
            :disabled="isReadOnly()"
            v-model="exitInfo.planRequest"
        />
        <v-checkbox
            label="License Denied"
            :disabled="isReadOnly()"
            v-model="exitInfo.licenseDenied"
        />
        <v-checkbox
            label="License Granted"
            :disabled="isReadOnly()"
            v-model="exitInfo.licenseGranted"
        />
        <v-checkbox
            label="Performance Incentive Eligible"
            :disabled="isReadOnly()"
            v-model="exitInfo.incentiveEligible"
        />
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" lg="8">
        <v-textarea
          :readonly="isReadOnly()"
          outlined
          label="Comment"
          rows="5"
          v-model="exitInfo.comment"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          @click="save()"
          color="primary"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {ScopeSeverity} from "@/shared/model/ScopeSeverity";
import {evalApi} from "@/plugins/axios";

export default {

  props: {
    evaluation: Object,
    exitInfo: Object
  },

  mounted () {
    window.scrollTo(0, 0)
    console.log(this.evaluation)
  },

  data() {
    return {
      followUp: "",
      snackbar: {
        visible: false,
        color: "#2a602c",
        text: ""
      },
      scopeSeverityList: ScopeSeverity.data,
    }
  },

  computed: {
    scopeCodes() {
      return this.scopeSeverityList.map((item) => {
        return item.code
      })
    }
  },

  methods: {
    isReadOnly() {
      return false
      /*
      if (this.evaluation.endDate != null) {
        let now = new Date()
        let expDate = new Date(this.evaluation.endDate)
        expDate.setMonth(expDate.getMonth() + 2)
        return now > expDate
      }
      return false
       */
    },

    isEmpty(str) {
      return (!str || str.length === 0 );
    },

    scopeLevelChange() {
      this.exitInfo.scope = null
      this.exitInfo.level = null
      for(let i=0; i<this.scopeSeverityList.length; i++) {
        if (this.exitInfo.scopeLevelCode === this.scopeSeverityList[i].code) {
          this.exitInfo.scope = this.scopeSeverityList[i].scope
          this.exitInfo.level = this.scopeSeverityList[i].level
        }
      }
    },

    scheduleFollowUp(newEval, pcr) {
      evalApi.get("/evaluationsImport/evalNumExists/" + newEval.evaluationNumber)
          .then(res => {
            if(!res.data) {
              if(this.isEmpty(newEval.dueDate) || this.isEmpty(newEval.endDate)) {
                this.snackbar.color = "warning"
                this.snackbar.text = "Evaluation and Exit information saved. \nEntered and Exited dates are required to schedule a Follow-up."
                this.snackbar.visible = true
                return
              }

              console.log(newEval)

              evalApi.post("/evaluationsImport/create", {
                providerNumber: newEval.providerNumber,
                providerType: newEval.providerType,
                providerName: newEval.providerName,
                evaluationNumber: newEval.evaluationNumber,
                surveyType: newEval.surveyType,
                department: newEval.department,
                endDate: null,
                dueDate: null,
                initialIndicator: newEval.initialIndicator,
                chowIndicator: newEval.chowIndicator,
                evaluationType: newEval.evaluationType,
                fiscalYear: newEval.fiscalYear,
                district: newEval.district,
                providerCounty: newEval.providerCounty,
                revisitNumber: pcr,
                status: newEval.status = "ACTIVE",
                jobName: newEval.jobName = "EvaluationsImport"
              }).then(() => {
                this.snackbar.color = "#2a602c"
                this.snackbar.text += "\n" + newEval.evaluationNumber + " has been created."
                this.snackbar.visible = true
              }).catch(error => {
                this.snackbar.color = "#b60000"
                this.snackbar.text = "An error occurred creating the evaluation record: >>>> " + error
                this.snackbar.visible = true
              })
            } else {
              this.snackbar.color = "warning"
              this.snackbar.text += "\n" + newEval.evaluationNumber + " already exists."
              this.snackbar.visible =true
            }
      })
    },

    async saveExitInfo() {
      this.exitInfo.evaluationNumber = this.evaluation.evaluationNumber
      evalApi.post("/exit/save", {
        exitInfo: this.exitInfo
      }).then(() => {
        this.snackbar.text = "Evaluation and Exit information saved.";
        if(this.followUp === 'y') {
          let newEval = JSON.parse(JSON.stringify(this.evaluation));
          const index = newEval.evaluationNumber.indexOf("-");
          let revisitNumber = parseInt(newEval.evaluationNumber.substring(index + 1)) + 1;
          newEval.evaluationNumber = newEval.evaluationNumber.substr(0,index+1) + revisitNumber;
          this.scheduleFollowUp(newEval, revisitNumber);
        } else {
          this.snackbar.visible = true;
        }
      }).catch(error => {
        this.snackbar.color = "#b60000"
        this.snackbar.text = "An error occurred saving the evaluation record: >>>> " + error
        this.snackbar.visible = true
      })

    },

    save() {
      this.evaluation.jobName = "EvaluationsImport"
      evalApi.post("/evaluationsImport/dataImport", {
        evaluationList: [this.evaluation]
      }).then(() => {
        this.snackbar.color = "#2a602c"
        this.snackbar.text = "Evaluation information saved.";
        this.saveExitInfo()
      }).catch(error => {
        this.snackbar.color = "#b60000"
        this.snackbar.text = "An error occurred updating the evaluation record: >>>> " + error
        this.snackbar.visible = true
      })
    }
  }
}
</script>

<style>

.v-input--radio-group legend.v-label {
  font-size: 18px;
  font-weight: bold;
}

.v-input__control {
  padding-top: 0px;
  padding-bottom: 0px;
}

.text-black input {
  color: black !important;
}

</style>